import { createGlobalStyle } from 'styled-components';
import nextArrow from '../assets/images/forward-arrow-white.svg';
import prevArrow from '../assets/images/back-arrow-white.svg';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000000;
    --white: #ffffff;
    --grey: #e5e5e5;
  }
  html {
    font-size: 10px;
  }

  body {
    font-size: 2rem;
    margin: 0;
    @media (max-width: 1000px) {
      font-size: 1.5rem;
    }
    @media (min-width: 2000px) {
      font-size: 3rem;
    }
  }

  p {
    margin: 0;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  body.ReactModal__Body--open {
    overflow-y: hidden;
  }

  body.light-mode {
    background-color:  var(--white);
    color:  var(--black);
    transition: background-color 0.3s ease;

    --border-color: #000;

    nav ul {
      background-color:  var(--white);
    }

    svg {
      fill: #000;
    }

    a {
      color: var(--black);
    }

    #category-filter {
      @media (max-width: 767px) {
        background: #fff;
      }
    }
  }

  body.dark-mode {
    background-color: var(--black);
    color: var(--white);

    --border-color: #fff;

    nav ul {
      background-color:  var(--black);
    }

    svg {
      fill: #fff;
    }

    a,
    .btn-category {
      color: var(--white);
    }

    #category-filter {
      @media (max-width: 767px) {
        background: #000;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }

  .btn {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    text-decoration: underline;
    position: fixed;
    right: 4rem;
    top: 4rem;
    color: var(--white);
    z-index: 10000;

    @media (min-width: 2000px) {
      font-size: 3rem;
    }
  }

  .btn-category {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    &:active {
      outline: none;
      border: none;
    }
    &:focus {
      outline: 0;
    }
    @media (max-width: 1000px) {
      font-size: 1.5rem;
      padding: 0;
    }
    @media (min-width: 2000px) {
      font-size: 3rem;
    }
  }
  
  .inactive {
    filter: grayscale();
  }

  .photosOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.75);
    z-index: 9999;
    display: flex;
    align-items: center;

    .photosOverlayContent {
      width: 100%;

      > div {
        max-width: none !important;
        padding: 0 4rem;
      }

      &:focus {
        outline: none;
      }

      .swiper-container {
        width: 100%;
        height: 80vh;

        .swiper-button-next {
          background-image: url(${nextArrow}) !important;
        }

        .swiper-button-prev {
          background-image: url(${prevArrow});
        }

      }

      .swiper-wrapper {
        align-items: center;

        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: auto !important;
            height: 100% !important;

            @media screen and (max-width: 1499px) {
              height: auto !important;
            }
          }
        }
      }

      

      /* .swiper-container {
        width: 75%;
        height: 80vh;

        .swiper-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      } */

      
    }  
  }
`;

export default GlobalStyles;
