import { createGlobalStyle } from 'styled-components';

import font from '../assets/fonts/Alpha-Regular.woff';

const Typography = createGlobalStyle`
  @font-face {
    font-family: Alpha;
    src: url(${font});
    font-display: swap;
  }
  html, button {
    font-family: Alpha, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }
  p, li, button {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6, button {
    font-weight: normal;
    margin: 0;
  }
  a {
    color: var(--black);
    text-decoration-color: var(--red);
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }
`;

export default Typography;
