import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  height: 63px;
  padding: 0 calc(6.5% / 3);
  display: flex;
  border-top: 1.2px solid var(--border-color);
  display: flex;
  align-items: center;
  p {
    font-size: 1rem;
  }

  @media (min-width: 2000px) {
    height: 99px;
    p {
      font-size: 2rem;
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <p>&copy; Nathan Ceddia {new Date().getFullYear()}</p>
    </FooterStyles>
  );
}
