import React from 'react';
import styled from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Footer from './Footer';
import Nav from './Nav';

const SiteWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <SiteWrapper>
        <Nav />
        {children}
        <Footer />
      </SiteWrapper>
    </>
  );
}
